import React from "react";

const AboutPage: React.FC = () => {

  return (
    <div className="p-6">
        {/* Brief bio, image to right (flexbox to go under), maybe ai upscale picture? */}
        Coming Soon!
    </div>
  );
};

export default AboutPage;
