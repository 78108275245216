import React from "react";
import ParticleWallpaper from "./ParticleWallpaper";

const LinksPage: React.FC = () => {
  const externalLinks = [
    {
      link: "https://photos.jademathre.com",
      displayName: "Photos",
      icon: "photoprism.svg",
    },
    {
      link: "https://cloud.jademathre.com",
      displayName: "Nextcloud",
      icon: "nextcloud.svg",
    },
    {
      link: "https://nas.jademathre.com",
      displayName: "NAS",
      icon: "unraid.svg",
    },
    {
      link: "https://router.jademathre.com",
      displayName: "Router",
      icon: "rog.svg",
    },
    {
      link: "https://hubitat.jademathre.com",
      displayName: "Hubitat",
      icon: "hubitat.png",
    },
    {
      link: "https://plex.jademathre.com",
      displayName: "Plex",
      icon: "plex.svg",
    },
    {
      link: "https://netdata.jademathre.com",
      displayName: "NetData",
      icon: "netdata.svg",
    },
    {
      link: "https://isos.jademathre.com",
      displayName: "ISOs",
      icon: "isos.svg",
    },
    {
      link: "https//filebot.jademathre.com",
      displayName: "FileBot",
      icon: "filebot.svg",
    },
    {
      link: "https://adminer.jademathre.com",
      displayName: "Adminer",
      icon: "adminer.png",
    },
    {
      link: "https://nginx.jademathre.com",
      displayName: "NGINX",
      icon: "nginx-proxy-manager.svg",
    },
    {
      link: "https://2020calcs.jademathre.com",
      displayName: "2020 Calcs",
      icon: "calcs.png",
    },
    {
      link: "https://chores.jademathre.com",
      displayName: "Chores",
      icon: "chores.svg",
    },
  ];

  return (
    <div className="flex-1">
      <ParticleWallpaper style={{ position: "fixed", zIndex: -1 }} />
      <ul className="p-6 flex flex-wrap justify-center">
        {externalLinks.map(({ link, displayName, icon }) => (
          <li
            key={link}
            className="flex flex-col items-center w-24 md:w-32 transition-transform transform scale-75 hover:scale-100"
          >
            <a
              href={link}
              className="nav-link flex flex-col items-center text-xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`/assets/${icon}`}
                alt={`${displayName} icon`}
                className="w-full h-auto"
              />
              <span className="mt-2 text-center">{displayName}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinksPage;
