import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import styles from "./Header.module.scss";

const Header: React.FC = () => {
  const headerLinks = [
    {
      link: "/",
      displayName: "Home",
    },
    {
      link: "skyCastle",
      displayName: "SkyCastle",
    },
    {
      link: "dev",
      displayName: "Developer",
    },
    {
      link: "research",
      displayName: "Researcher",
    },
    {
      link: "resume",
      displayName: "Résumé",
    },
    {
      link: "about",
      displayName: "About",
    },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <header className="h-[10vh] bg-zinc-900 p-6 flex justify-between items-center fixed top-0 w-full z-50">
      {/* Desktop */}
      <Link
        to="/"
        className="hidden md:flex text-2xl font-bold hover:text-jade transition"
      >
        Jade Mathre
      </Link>
      {/* Mobile */}
      <Link
        to="/"
        className="md:hidden text-2xl font-bold hover:text-jade transition"
      >
        <img
          src={"logo512.png"}
          alt={`Jade Mathre Logo icon`}
          className="w-12 h-auto"
        />
      </Link>
      {/* Desktop */}
      <nav className="flex space-x-4 ml-auto mr-auto items-center">
        {headerLinks.map(({ link, displayName }) => (
          <Link
            key={link}
            to={`${link}`}
            className="hidden md:flex hover:text-jade transition"
          >
            {displayName}
          </Link>
        ))}
      </nav>
      {/* Mobile */}
      <button
        onClick={() => setIsNavOpen(!isNavOpen)}
        className="md:hidden focus:outline-none"
      >
        <Bars3Icon className="h-6 w-6 z-20 hover:text-jade transition" />
      </button>
      <div className={isNavOpen ? styles.showMenuNav : styles.hideMenuNav}>
        <XMarkIcon
          className="h-6 w-6 absolute top-6 right-6 hover:text-jade transition"
          onClick={() => {
            setIsNavOpen(false);
          }}
        />
        <ul className="flex flex-col items-center justify-between min-h-[250px]">
          {headerLinks.map(({ link, displayName }) => (
            <Link
              key={link}
              to={`${link}`}
              className="hover:text-jade transition my-8 text-xl"
              onClick={() => {
                setIsNavOpen(false);
              }}
            >
              <li>{displayName}</li>
            </Link>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
