import React from "react";

const ResumePage: React.FC  = () => {
  return (
    <iframe
      className="flex-1"
      src="/Jade-Mathre-Resume.pdf"
      width="100%"
      title="PDF Embedding Example"
    />
  );
};
export default ResumePage;
