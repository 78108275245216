import { BrowserRouter, Route, Routes } from "react-router-dom";
import LinksPage from "./LinksPage";
import Header from "./Header";
import "./output.css";
import "./App.scss";
import DeveloperPage from "./DeveloperPage";
import ResearcherPage from "./ResearcherPage";
import AboutPage from "./AboutPage";
import ResumePage from "./ResumePage";
import SkyCastlePage from "./SkyCastle";

function App() {
  return (
    <div className="flex flex-col h-full pt-[10vh]">
      <BrowserRouter>
        <Header />
        <div className="flex flex-col h-[90vh]">
          <Routes>
            <Route path="/" element={<SkyCastlePage />} />
            <Route path="skyCastle" element={<SkyCastlePage />} />
            <Route path="links" element={<LinksPage />} />
            <Route path="dev" element={<AboutPage />} />
            <Route path="research" element={<AboutPage />} />
            <Route path="resume" element={<ResumePage />} />
            <Route path="about" element={<AboutPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
