import React from "react";

const SkyCastlePage: React.FC = () => {

  return (
    <div className="p-6">
        {/* Brief bio, image to right (flexbox to go under), maybe ai upscale picture? */}
        Under construction. Please contact admin@jademathre.com for any inquiries. Thank you!
    </div>
  );
};

export default SkyCastlePage;
