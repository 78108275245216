import React, { useEffect, useRef, CSSProperties, useState } from "react";

interface Particle {
  x: number;
  y: number;
  speed: number;
  color: string;
  size: number;
  alpha: number;
  alphaDecay: number;
}

interface ParticleWallpaperProps {
  style?: CSSProperties;
}

const ParticleWallpaper: React.FC<ParticleWallpaperProps> = ({ style }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let particleCount = 0;
    const maxParticles = 100;
    const particles: Particle[] = [];

    function generateVibrantColor() {
        const r = Math.floor((Math.random() * 0.5 + 0.5) * 255);
        const g = Math.floor((Math.random() * 0.5 + 0.5) * 255);
        const b = Math.floor((Math.random() * 0.5 + 0.5) * 255);
    
        return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
    }
    
    const colors = Array.from({length: 20}, generateVibrantColor);
    

    // const colors = ["#FFB6C1", "#FFD700", "#87CEFA", "#FF69B4", "#90EE90"]; // Pastel colors

    const createParticle = () => {
      const color = colors[Math.floor(Math.random() * colors.length)].slice(1); // remove '#'
      const [r, g, b] = [
        parseInt(color.slice(0, 2), 16),
        parseInt(color.slice(2, 4), 16),
        parseInt(color.slice(4, 6), 16),
      ];

      const particle: Particle = {
        x: Math.random() * canvas.width,
        y: canvas.height,
        speed: Math.exp(Math.random()),
        color: `rgba(${r}, ${g}, ${b}, `, // store RGB components for later
        size: Math.exp(Math.random()) ** 2 + Math.exp(Math.random()) ** 2,
        alpha: 1,
        alphaDecay: Math.random() * 0.02 + 0.001,
      };

      particles.push(particle);
    };

    const updateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#000000"; // Black background
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle, index) => {
        // Update the alpha value based on the particle's vertical position
        particle.alpha = particle.alpha - (particle.alpha * particle.alphaDecay);

        ctx.fillStyle = `${particle.color}${particle.alpha})`; // complete RGBA string
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();

        particle.y -= particle.speed;

        if (particle.y < 0) {
          particles.splice(index, 1);
          particleCount = Math.min(particleCount - 1, maxParticles);
        }
      });

      while (particles.length < particleCount) {
        createParticle();
      }

      // Gradually increase the number of particles until reaching maxParticles
      if (particleCount < maxParticles) {
        if (Math.random() < 0.07) {
          particleCount++;
        }
      }

      requestAnimationFrame(updateParticles);
    };

    updateParticles();
  }, []);

  return (
    <canvas
      className="flex-1"
      ref={canvasRef}
      width={dimensions.width}
      height={dimensions.height}
      style={style}
    />
  );
};

export default ParticleWallpaper;
